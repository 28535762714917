class FetchUtil {

    static DEFAULT_TIMEOUT = 30000;
    static LOGIN_BY_TOKEN_TIMEOUT = 5000;

    static fetchGet(url, token, boardToken, onSuccess, onResponseNotOK, onError, onForbitten, timeout) {
        return baseFetch(url, token, boardToken, 'GET', {}, onSuccess, onResponseNotOK, onError, onForbitten, timeout);
    }

    static fetchPost(url, token, boardToken, data, onSuccess, onResponseNotOK, onError, onForbitten, timeout) {
        return baseFetch(url, token, boardToken,'POST', data, onSuccess, onResponseNotOK, onError, onForbitten, timeout);
    }

    static fetchPut(url, token, boardToken,data, onSuccess, onResponseNotOK, onError, onForbitten, timeout) {
        return baseFetch(url, token, boardToken,'PUT', data, onSuccess, onResponseNotOK, onError, onForbitten, timeout);
    }

    static fetchDelete(url, token, boardToken,data, onSuccess, onResponseNotOK, onError, onForbitten, timeout) {
        return baseFetch(url, token, boardToken,'DELETE', data, onSuccess, onResponseNotOK, onError, onForbitten, timeout);
    }
}

function withTimeout(ms, promise) {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            reject(new Error("Zeitüberschreitung"))
        }, ms);
        promise.then(resolve, reject)
    })
}

function baseFetch(url, token, boardToken, method, data, onSuccess, onResponseNotOK, onError, onForbitten, timeout = FetchUtil.DEFAULT_TIMEOUT) {
    let fetchParameter;
    if (method !== 'GET') {
        fetchParameter = {
            body: JSON.stringify(data),
            headers: {'content-type': 'application/json', 'x-dh-eventoperator-token': token, 'x-dh-eventoperator-board-token': boardToken},
            method: method,
            credentials: 'include'
        }
    } else {
        fetchParameter = {
            headers: {'x-dh-eventoperator-token': token, 'x-dh-eventoperator-board-token': boardToken},
            method: method,
            credentials: 'include'
        }
    }
    withTimeout(timeout, fetch(url, fetchParameter))
        .then(response => {
            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else if (contentType && contentType.indexOf("text/plain") !== -1) {
                    return response.text();
                }
                return "";
            } else if( response.status === 401 || response.status === 403 ) {
                // keine Anmeldung möglich
                console.log(response.status);
                if( response.status === 401) {
                    if(onForbitten !== null && onForbitten !== undefined) {
                        onForbitten();
                    }
                }
                onError("Keine Anmeldung möglich.");
                return Promise.reject("Error fetching " + url);
            }

            return response.json()
                .then(json => {
                    console.log("Error fetching " + url + ": Status: " + json.status + "; Message: " + json.message, json);
                    onResponseNotOK(json);
                    return Promise.reject("Error fetching " + url + ": Status: " + json.status + "; Message: " + json.message)
                });
        }, error => {
            console.log("Error fetching " + url + ": " + error, error);
            onError(error);
            return Promise.reject("Error fetching " + url + ": " + error);
        })
        .then(json => onSuccess(json), error => console.error(error))
}

export default FetchUtil;
