import {useCallback, useEffect} from 'react';
import {Button, Dialog} from "@mui/material";

export function ExitDialog({onClose, onOk}) {

    const onKeyDown = useCallback( (event) => {
        event.preventDefault();
        switch (event.key) {
            case "Escape":
            case "*":
                onClose();
                break;
            default:
                onOk();
                break;
        }
        event.stopPropagation();
    }, [onOk, onClose]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    }, [onKeyDown]);

    return <Dialog
        sx={{'& .MuiDialog-paper': {minWidth: "100%", height: "100%", backgroundColor:"#ac1c27", zIndex:20000}}}
        open={true} disableEscapeKeyDown={true}>
        <div style={{textAlign:"center"}}>
            <div style={{marginLeft: 20, color: "white", fontSize: 24}}>
                <h2>Möchtest du den Dialog wirklich beenden?</h2>
            </div>
            <div>
                <Button style={{color: "white", fontSize: 30}} variant="text" onClick={() => onOk()}>Beenden (beliebige Taste)</Button>
            </div>
            <div>
                <Button style={{color: "white", fontSize: 30}} variant="text" onClick={() => onClose()}>Abbrechen (ESC oder *)</Button>
            </div>
        </div>
    </Dialog>
}
