import React from 'react';
import ConnectionStatus from "../dashboard/ConnectionStatus";

export function ErgebnisErneutUebertragenComponent({retries, onSend}) {

    return <>
        <div style={{backgroundColor: "#ac1c27", color: "white", fontSize: 30}}>
            <div onClick={() => onSend()} style={{cursor: "pointer"}}>
                <div style={{marginTop: 10, marginBottom: 10, fontWeight: "bold"}}>Fehler ({retries}) beim Übertragen an darthelfer.de</div>
                <div style={{marginBottom: 10}}>....Klick hier für erneutes Übertragen...</div>
                <div style={{marginBottom: 10}}>Alternativ Ergebnis von Hand im Turnier/Liga eintragen.</div>
            </div>
            <div style={{marginBottom: 10}}><ConnectionStatus/></div>
        </div>
    </>
}
