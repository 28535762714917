import React, {useCallback, useEffect, useState} from "react";
import FetchUtil from "../util/FetchUtil";
import {getFromLocalStorage} from "../localStorageZugriff";

export default function ConnectionStatus() {

    const URL = getFromLocalStorage("dh_url");
    const token = getFromLocalStorage("dh_token");
    const boardToken = getFromLocalStorage("dh_board_token");

    const [checkConnection, setCheckConnection] = useState(1);

    const getPingURL = () => {
        if( URL.includes("localhost")) {
            return "https://localhost:8443/api/public/ping";
        } else if( URL.includes("test.darthelfer")) {
            return "https://test.darthelfer.de/api/public/ping";
        } else  {
            return "https://www.darthelfer.de/api/public/ping";
        }
    }

    const [status, setStatus] = useState("UNKNOWN");

    const check = useCallback(() => {
        setStatus("PENDING");
        FetchUtil.fetchGet(getPingURL(), token, boardToken,
            () => {
                setStatus("CONNECTED");
            },
            () => {
                setStatus("FAILED");
            },
            () => {
                setStatus("FAILED");
            }
        );

    }, [setStatus]);

    useEffect(() => {
        check();
    }, [check]);

    function isOk() {
        return status === "CONNECTED";
    }

    return <div>
        <div style={{padding: 20, fontSize: 20, color: "white", textAlign:"center"}}>
            { isOk() && <>
                <div>Internet verfügbar. Darthelfer-Server kann aufgerufen werden.</div>
            </>}
            { !isOk() && <div>
                <div>Internet <span style={{textDecoration:"underline", fontWeight:"bold"}}>nicht verfügbar</span> oder Darthelfer-Server ausgefallen.</div>
                <div  style={{cursor:"pointer"}} onClick={() => {
                    setCheckConnection(checkConnection + 1);
                    check();
                }}>...Klicke hier um die Internetverbindung erneut zu testen ({checkConnection})...</div>
            </div>}
        </div>
    </div>
}
